/* styles.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
}

.content {
  max-width: 1200px;
  width: 100%;
  text-align: center;
  position: relative;
}

.logo {
  margin-bottom: 2.5rem;
}

.logo span {
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.025em;
}

.title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  background: linear-gradient(to right, #fff, #888);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

@media (min-width: 768px) {
  .title {
    font-size: 4.5rem;
  }
}

.description {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #a0a0a0;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .description {
    font-size: 1.5rem;
  }
}

.ceo-profile {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ceo-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: linear-gradient(to right, #9333ea, #3b82f6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ceo-avatar span {
  font-size: 1.125rem;
  font-weight: 700;
}

.ceo-info {
  margin-left: 1rem;
  text-align: left;
}

.ceo-name {
  color: #fff;
  font-weight: 500;
}

.ceo-title {
  color: #a0a0a0;
  font-size: 0.875rem;
}

.launch-date {
  display: inline-block;
  border: 1px solid #333;
  border-radius: 9999px;
  padding: 0.5rem 1.5rem;
  color: #a0a0a0;
  margin-bottom: 4rem;
}

.feature-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 5rem;
}

@media (min-width: 768px) {
  .feature-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .feature-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.feature-card {
  position: relative;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid #333;
  background-color: rgba(17, 17, 17, 0.2);
  backdrop-filter: blur(8px);
  text-align: left;
  transition: border-color 0.3s;
}

.feature-card:hover {
  border-color: #555;
}

.feature-gradient {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
}

.feature-card:hover .feature-gradient {
  opacity: 1;
}

.feature-card:nth-child(1) .feature-gradient {
  background: linear-gradient(to top right, rgba(147, 51, 234, 0.05), rgba(59, 130, 246, 0.05));
}

.feature-card:nth-child(2) .feature-gradient {
  background: linear-gradient(to top right, rgba(59, 130, 246, 0.05), rgba(6, 182, 212, 0.05));
}

.feature-card:nth-child(3) .feature-gradient {
  background: linear-gradient(to top right, rgba(6, 182, 212, 0.05), rgba(16, 185, 129, 0.05));
}

.feature-card:nth-child(4) .feature-gradient {
  background: linear-gradient(to top right, rgba(16, 185, 129, 0.05), rgba(234, 179, 8, 0.05));
}

.feature-card:nth-child(5) .feature-gradient {
  background: linear-gradient(to top right, rgba(234, 179, 8, 0.05), rgba(249, 115, 22, 0.05));
}

.feature-card:nth-child(6) .feature-gradient {
  background: linear-gradient(to top right, rgba(249, 115, 22, 0.05), rgba(147, 51, 234, 0.05));
}

.feature-title {
  position: relative;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.feature-description {
  position: relative;
  color: #a0a0a0;
}

.background-gradient {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24rem;
  height: 24rem;
  background: linear-gradient(to right, rgba(147, 51, 234, 0.1), rgba(59, 130, 246, 0.1));
  border-radius: 9999px;
  filter: blur(100px);
  z-index: -10;
}

.footer {
  padding: 2rem 0;
  border-top: 1px solid #333;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
  }
}

.footer-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.footer-link {
  color: #a0a0a0;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #fff;
}

.copyright {
  color: #666;
  font-size: 0.875rem;
}
